<template>
  <div>
    <sales-dashboard v-if="$route.params.name === 'Sale'"/>
    <projects-dashboard v-if="$route.params.name === 'Project'"/>
  </div>
</template>

<script>
export default {
  components: {
    salesDashboard: () => import('@/components/CustomModules/SalesDashboard.vue'),
    projectsDashboard: () => import('@/components/CustomModules/ProjectsDashboard.vue')
  }
}
</script>
